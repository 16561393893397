import { CREATE_TRADER_INIT_VALUES } from './constants';
export const isProfileComplete = (profile) => { var _a, _b; return !!profile && ((_a = profile.displayName) === null || _a === void 0 ? void 0 : _a.length) > 0 && ((_b = profile.location) === null || _b === void 0 ? void 0 : _b.length) !== 0; };
export const getActiveProfile = (user) => user.profiles.find(({ id }) => id === user.activeProfileId);
export const mapDataToProfileValues = (user) => {
    var _a, _b;
    const activeUserProfile = getActiveProfile(user);
    const contactsSet = ((_a = activeUserProfile === null || activeUserProfile === void 0 ? void 0 : activeUserProfile.contacts) === null || _a === void 0 ? void 0 : _a.reduce((accumulator, current, index) => {
        const currentKey = `${current.type}-${index}`;
        accumulator[`${currentKey}`] = {
            key: currentKey,
            type: current.type,
            value: current.contact,
        };
        return accumulator;
    }, {})) || {};
    return {
        username: (activeUserProfile === null || activeUserProfile === void 0 ? void 0 : activeUserProfile.username) || '',
        displayName: (activeUserProfile === null || activeUserProfile === void 0 ? void 0 : activeUserProfile.displayName) || '',
        description: (activeUserProfile === null || activeUserProfile === void 0 ? void 0 : activeUserProfile.description) || '',
        contacts: contactsSet,
        location: (activeUserProfile === null || activeUserProfile === void 0 ? void 0 : activeUserProfile.location) || '',
        initialUsername: (activeUserProfile === null || activeUserProfile === void 0 ? void 0 : activeUserProfile.username) || '',
        initialImage: (activeUserProfile === null || activeUserProfile === void 0 ? void 0 : activeUserProfile.profilePicture) ? {
            avatar: activeUserProfile.profilePicture.avatar || '',
            original: activeUserProfile.profilePicture.original || '',
            thumbnail: activeUserProfile.profilePicture.thumbnail || '',
        } : undefined,
        imageEditorState: {
            isOpen: false,
            prevStep: (activeUserProfile === null || activeUserProfile === void 0 ? void 0 : activeUserProfile.profilePicture) ? 2 : 0,
            initialImage: ((_b = activeUserProfile === null || activeUserProfile === void 0 ? void 0 : activeUserProfile.profilePicture) === null || _b === void 0 ? void 0 : _b.avatar) || '',
        },
        isTraderProfile: (activeUserProfile === null || activeUserProfile === void 0 ? void 0 : activeUserProfile.type) === 'TRADER',
        // before image apply we consider that its null
        image: null,
        displayCompleteProfile: !isProfileComplete(activeUserProfile),
    };
};
export const mapProfileDataAfterUpdate = (profile) => {
    var _a, _b;
    const contactsSet = ((_a = profile === null || profile === void 0 ? void 0 : profile.contacts) === null || _a === void 0 ? void 0 : _a.reduce((accumulator, current, index) => {
        const currentKey = `${current.type}-${index}`;
        accumulator[`${currentKey}`] = {
            key: currentKey,
            type: current.type,
            value: current.contact,
        };
        return accumulator;
    }, {})) || {};
    return Object.assign(Object.assign({}, profile), { contacts: contactsSet, initialUsername: profile.username, location: profile.location || '', imageEditorState: {
            isOpen: false,
            prevStep: (profile === null || profile === void 0 ? void 0 : profile.profilePicture) ? 2 : 0,
            initialImage: ((_b = profile === null || profile === void 0 ? void 0 : profile.profilePicture) === null || _b === void 0 ? void 0 : _b.avatar) || '',
        }, initialImage: (profile === null || profile === void 0 ? void 0 : profile.profilePicture) ? {
            avatar: profile.profilePicture.avatar || '',
            original: profile.profilePicture.original || '',
            thumbnail: profile.profilePicture.thumbnail || '',
        } : undefined, image: null });
};
export const mapDataToProfile = (user) => {
    const profile = getActiveProfile(user);
    return {
        id: profile.id,
        userId: user.id,
        createdAt: profile.createdAt,
        username: profile.username || '',
        displayName: profile.displayName || '',
        description: profile.description || '',
        contacts: profile.contacts,
        location: (profile === null || profile === void 0 ? void 0 : profile.location) || undefined,
        profilePicture: profile.profilePicture,
        exchangeConnectionProfile: [],
        type: profile.type,
        // TODO: add portfolios
        portfolio: [],
        followersCount: 0,
        followingCount: 0,
        isVerifiedTrader: profile.isVerifiedTrader,
    };
};
export const definePrefilledValues = (clientProfile) => {
    let isPrefilled = false;
    const { username, displayName, location } = clientProfile;
    // default nickname is string with profile id
    const isDefaultNickname = Boolean((username === null || username === void 0 ? void 0 : username.length) > 0 && !Number.isNaN(Number(username)));
    const prefilledValues = {
        initialUsername: isDefaultNickname === false ? `${username}trade` : '',
        username: isDefaultNickname === false ? `${username}trade` : '',
        displayName: (displayName === null || displayName === void 0 ? void 0 : displayName.length) ? `${clientProfile.displayName} Trade` : '',
        location: (location === null || location === void 0 ? void 0 : location.length) ? location : '',
    };
    if ((username === null || username === void 0 ? void 0 : username.length) || (displayName === null || displayName === void 0 ? void 0 : displayName.length) || (location === null || location === void 0 ? void 0 : location.length)) {
        isPrefilled = true;
    }
    return { isPrefilled, prefilledValues };
};
export const getTraderValues = (clientProfile) => ({
    values: Object.assign({}, CREATE_TRADER_INIT_VALUES),
});
export const sortByPopularCountries = (countries, popularCountriesList) => {
    const sortedCountries = [...countries].sort((prevCountry, nextCountry) => {
        const prevCountryValue = popularCountriesList[prevCountry.code];
        const nextCountryValue = popularCountriesList[nextCountry.code];
        if (prevCountryValue && nextCountryValue)
            return nextCountryValue - prevCountryValue;
        if (prevCountryValue)
            return -1;
        if (nextCountryValue)
            return 1;
        if (prevCountry.name_en < nextCountry.name_en)
            return -1;
        return 1;
    });
    return {
        popularCountries: sortedCountries.slice(0, 8),
        otherCountries: sortedCountries.slice(8, sortedCountries.length - 1),
    };
};
export const prepareContactsSet = (contacts) => {
    const res = [];
    Object.values(contacts).map((contact) => {
        res.push({
            type: `${contact.type}`,
            value: contact.value,
        });
        return contact;
    });
    return res;
};
export const mapProfileFormToReq = (values) => {
    const { description, username, displayName, location, } = values;
    const contactsSet = prepareContactsSet(values.contacts);
    return {
        username,
        displayName: displayName.trim().length ? displayName : null,
        location: (location === null || location === void 0 ? void 0 : location.length) ? location : undefined,
        contacts: contactsSet,
        description,
    };
};
export const getProfileUsername = (profile) => ((profile === null || profile === void 0 ? void 0 : profile.username) ? `@${profile.username}` : '');
export const getProfileDisplayName = (profile) => {
    if (profile.displayName) {
        return profile.displayName;
    }
    return (profile === null || profile === void 0 ? void 0 : profile.username) ? `@${profile.username}` : '';
};
